/* eslint-disable no-unused-vars */
import { isRejectedWithValue } from '@reduxjs/toolkit';

const kifAdminApiErrorHandler = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    }
  }
  return next(action);
};

export default kifAdminApiErrorHandler;
