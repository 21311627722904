import { createSlice } from '@reduxjs/toolkit';

export const mainSlice = createSlice({
  name: 'main',
  initialState: { user: {}, loginMeta: {} },
  reducers: {
    handleUser: (state, payload) => {
      state.user = payload;
    },
    handleLoginMeta: (state, { payload }) => {
      state.loginMeta = payload;
    },
  },
});

export const { handleUser, handleLoginMeta } = mainSlice.actions;

export default mainSlice.reducer;
