import { configureStore } from '@reduxjs/toolkit';
import { kifAdminApi } from '../services/kif_admin_api';
import kifAdminApiErrorHandler from '../services/kif_admin_api/errorHandler';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: {
    [kifAdminApi.reducerPath]: kifAdminApi.reducer,
    ...rootReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(kifAdminApi.middleware)
    .concat(kifAdminApiErrorHandler),
});

export default store;
